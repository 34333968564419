import { PeopleIcon } from '../../../kit/icons/PeopleIcon';
import { RocketIcon } from '../../../kit/icons/RocketIcon';
import CalendarIcon from '../../../kit/icons/CalendarIcon';
import ClockIcon from '../../../kit/icons/ClockIcon';
import CodeIcon from '../../../kit/icons/CodeIcon';
import CardCheckIcon from '../../../kit/icons/CardCheckIcon';
import CardFreeIcon from '../../../kit/icons/CardFreeIcon';
import CardDSIcon from '../../../kit/icons/CardDSIcon';
import CardMessageIcon from '../../../kit/icons/CardMessageIcon';
import CardRubleIcon from '../../../kit/icons/CardRubleIcon';
import DSIcon from '../../../kit/icons/DSIcon';
import DSlargeIcon from '../../../kit/icons/DSlargeIcon';
import StonksIcon from '../../../kit/icons/StonksIcon';

const data = {
  seo: {
    title: 'Обучение Data Science в Эльбрус Буткемп | информация о курсе',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Присоединяйтесь к курсу по Data Science в Эльбрус Буткемп  и освойте навыки анализа данных, машинного обучения и искусственного интеллекта. Получите качественное образование от опытных преподавателей и подготовьтесь к карьере в сфере аналитики и Big Data.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content: 'Обучение Data Science в Эльбрус Буткемп | информация о курсе',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Присоединяйтесь к курсу по Data Science в Эльбрус Буткемп  и освойте навыки анализа данных, машинного обучения и искусственного интеллекта. Получите качественное образование от опытных преподавателей и подготовьтесь к карьере в сфере аналитики и Big Data.',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/08.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/08.JPG',
      },
    ],
  },
  careerDescription: {
    title: 'Кто такой Data Scientist?',
    description: [
      'Data Science — это область на стыке программирования, науки и аналитики, которая занимается интеллектуальной обработкой больших массивов данных для поиска полезных инсайтов',
      'Data Scientist занимается решением задач, поставленных со стороны бизнеса, с помощью данных. Это могут быть совершенно разные задачи:',
      [
        '— предсказания отказов оборудования;',
        '— обработка изображений с камер видеонаблюдения;',
        '— создание умных чат-ботов;',
        '— предсказание поведения клиентов на сайте.',
      ],
    ],
  },
  projects: {
    title: 'Реальные проекты',
    subtitle: 'В Вашем портфолио',
    data: {
      description:
        'Проекты в Эльбрус Буткемп — не симуляция настоящей работы, а заказы от компаний-партнеров, которые приглашают лучших студентов для дальнейшего сотрудничества. В процессе обучения на курсах Data Science онлайн вы разработаете 6 проектов для портфолио от компаний-партнеров, которые можно показать работодателю:',
      points: [
        '4 командных проекта',
        'Проект от заказчика',
        'Выпускной проект',
      ],
      list: [
        {
          description: 'Распознавание символов по уходу за одеждой',
          preview: 'momshelper',
          video: '',
        },
        {
          description: 'Распознавание языка жестов в реальном времени',
          preview: 'letstalk',
          video: '',
        },
        {
          description:
            'Cервис для определения марки, поколения и рестайлинга автомобиля по его фотографии.',
          preview: 'whatacar',
          video: '',
        },
        {
          description: 'Фитнес-приложение с персональным AI-тренером',
          preview: 'algym',
          video: '',
        },
        {
          description:
            'Подсчет клеток яичника китайского хомячка с использованием компьютерного зрения',
          preview: 'cellscount',
          video: '',
        },
      ],
    },
  },

  instruction: {
    title: 'Как попасть в Эльбрус Буткемп',
    subtitle: 'Инструкция',
    steps: [
      {
        icon: CardCheckIcon,
        title: 'Познакомьтесь с\u00A0Эльбрус Буткемп',
        image: 'meetElbrus',
        preSubTitle: 'Посетите День открытых дверей:',
        subTitles: [
          '[Ближайший — =eventDate=](=eventLink=)<!--rehype:ymSubmitEventType=upcomingEvent-->. Расскажем об обучении и почему оно эффективно',
          'Задайте интересующие вопросы выпускникам. Они поделятся своим опытом обучения и трудоустройства в IT-компанию',
        ],
        button: 'перейти к расписанию встреч',
        eventFilter: 'MEETING',
        buttonLink: '/events/',
        buttonMetric: 'onlineDSSchedule',
        freeStatus: CardFreeIcon,
        progress:
          'Понял формат буткемпа и получил ответы на интересующие вопросы',
        progressVal: 20,
      },
      {
        icon: CardDSIcon,
        title: 'Подготовьтесь к\u00A0интервью',
        image: 'examPreparation',
        subTitles: [
          'Начните изучать Python по [фирменным материалам →]()<!--rehype:modal=true&ymSubmitEventType=onlineDSMaterials&ymSubmitExtraEventType=lead&tags=DataScience_онлайн&program=DS\u0020Онлайн&leadAction=Подписка\u0020гайд&leadBlock=Как\u0020поступить&leadPage=DS_Онлайн&eventName=Материалы\u0020для\u0020подготовки&tags=дс\u0020онлайн-->',
          'Посетите бесплатные Мастер-классы',
          'Решите контрольные задачи',
          'При необходимости запишитесь на подготовительную программу с ментором [“Data Science Titanic” →](/ds-intense/)<!--rehype:ymSubmitEventType=divetoDS-->',
        ],
        button: 'Посетить мастер-класс',
        eventFilter: 'MASTER_KLASS',
        buttonLink: '/events/',
        buttonMetric: 'onlineDSMK',
        freeStatus: CardFreeIcon,
        progress:
          'Освоил основы Python. Запрограммировал первые собственные функции',
        progressVal: 50,
      },
      {
        icon: CardMessageIcon,
        title: 'Пройдите техническое интервью',
        image: 'techInterview',
        subTitles: [
          'Выберите удобную дату техинтервью',
          'Решите 3-4 задачи от ментора по видеосвязи',
        ],
        button: 'Перейти в личный кабинет',
        eventFilter: null,
        buttonLink:
          'https://edu.elbrusboot.camp/?utm_source=elbrus&utm_medium=roadmap_dsmsk&utm_campaign=button',
        target: '_blank',
        buttonMetric: 'visitLK',
        freeStatus: CardFreeIcon,
        progress: 'Забронировал место в Эльбрус Буткемп',
        progressVal: 75,
      },
      {
        icon: CardRubleIcon,
        title: 'Выберите дату старта обучения и варианты оплаты',
        image: 'startCourse',
        subTitles: [
          'Определитесь с удобной датой начала обучения',
          'Подпишите договор',
          'Выберите удобный [способ оплаты](/datascience/#price)<!--rehype:ymSubmitEventType=onlineDSPaymentMethods-->: рассрочка или единовременный платеж',
        ],
        button: 'посмотреть потоки обучения',
        eventFilter: null,
        buttonLink: '/datascience/#streams',
        buttonMetric: 'onlineDSStreams',
        freeStatus: null,
        progress:
          'Приступил к освоению востребованной профессии Data Scientist',
        progressVal: 100,
      },
    ],
  },
  questions: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      consultationFormSubmit: 'DSBottomConsultation',
    },
  },
  greetings: {
    bg: 'greetings-ds',
    location: {
      type: 'online',
      title: 'Онлайн',
    },
    duration: {
      number: '15',
      currency: 'недель',
    },
    type: 'основная программа',
    title: ['Data ', 'Science'],
    points: [
      'Погрузитесь в  профессию Data Scientist',
      'Составите портфолио для трудоустройства',
      'Получите индивидуальные сессии с ментором',
      'Курс включает программу помощи в трудоустройстве',
    ],
    features: [
      {
        icon: 'week',
        title: '15 недель',
      },
      {
        icon: 'time',
        title: '600 часов',
      },
    ],
    image: '/',
    action: 'Получить консультацию',
    ymEvents: {
      consultationFormSubmit: 'DSTopConsultation',
    },
  },
  benefits: [
    {
      type: 'fire',
      text: 'Погружение в профессию Data Scientist на языке Python',
    },
    {
      type: 'communication',
      text: 'Новая профессия за 600 часов занятий',
    },
    {
      type: 'career',
      text: '6 полноценных проектов в портфолио для будущего трудоустройства',
    },
    {
      type: 'diploma',
      text: 'Диплом о дополнительном профессиональном образовании',
    },
  ],
  aboutProfession: {
    title: 'О профессиях Data Science',
    ymEvent: 'DSMoreProfession',
    professionList: [
      {
        title: 'Аналитик данных',
        salary: 'Зарплата: 128 000 руб.*',
        description:
          'Собирает, обрабатывает, классифицирует, изучает и интерпретирует данные. Анализ данных помогает бизнесу принимать важные решения и оптимизировать процессы.',
      },
      {
        title: 'Data Scientist',
        salary: 'Зарплата: 167 000 руб.*',
        description:
          'Использует данные для построения моделей машинного обучения и работает на стыке трех областей знания: статистики, машинного обучения и программирования.',
      },
      {
        title: 'Дата-инженер',
        salary: 'Зарплата: 196 000 руб.*',
        description:
          'Строит инфраструктуру, позволяющую работать с большими данными. Он очищает информацию от ошибок, трансформирует ее, повышает корректность, создает конвейеры данных и отвечает за удобство их обработки.',
      },
    ],
    link: '/blog/putievoditiel-po-profiessiiam-data-science/',
  },
  howDoesBootcampWork: {
    title: 'Как устроен курс по Data Science',
    image: 'howBootcampWorks/600codingHours',
    pointsLists: [
      {
        title: 'Из чего состоит обучение',
        list: [
          'Живые лекции и обратная связь от преподавателя',
          'Код-ревью: проверка кода преподавателем',
          'Работа над проектами от заказчика',
          'Подготовка к собеседованиям вместе с карьерным коучем',
        ],
      },
      {
        title: 'Что получает выпускник',
        list: [
          'Профессия: Data Scientist',
          'Портфолио на GitHub: 4 групповых, проект от заказчика и выпускной проект',
          'Диплом о профессиональной переподготовке установленного образца',
          'Доступ к закрытому комьюнити в Data Science',
        ],
      },
    ],
  },
  tools: {
    title: {
      first: 'Инструменты',
      second: 'Вы изучите',
    },
    folder: 'ds',
    list: [
      {
        image: 'numpy',
        isMobileHidden: false,
      },
      {
        image: 'jupiter',
        isMobileHidden: false,
      },
      {
        image: 'postgresql',
        isMobileHidden: false,
      },
      {
        image: 'seaborn',
        isMobileHidden: false,
      },
      {
        image: 'pandas',
        isMobileHidden: false,
      },
      {
        image: 'githubcat',
        isMobileHidden: false,
      },
      {
        image: 'matplotlib',
        isMobileHidden: false,
      },
      {
        image: 'scikitlearn',
        isMobileHidden: false,
      },
      {
        image: 'docker',
        isMobileHidden: false,
      },
      {
        image: 'statsmodels',
        isMobileHidden: false,
      },
      {
        image: 'scipy',
        isMobileHidden: false,
      },
      {
        image: 'asynciotelegram',
        isMobileHidden: false,
      },
      {
        image: 'pytorch',
        isMobileHidden: false,
      },
      {
        image: 'catboost',
        isMobileHidden: false,
      },
      {
        image: 'streamlit',
        isMobileHidden: false,
      },

      {
        image: 'гltralytics',
        isMobileHidden: false,
      },
      {
        image: 'pyspark',
        isMobileHidden: false,
      },
      {
        image: 'huggingface',
        isMobileHidden: false,
      },
      {
        image: 'fastapi',
        isMobileHidden: true,
      },
      {
        image: 'kaggle',
        isMobileHidden: true,
      },
    ],
  },
  schedule: {
    title: 'Расписание дня',
    subtitle: 'Примерное',
    list: [
      {
        time: '9:00 − 9:15',
        topic: 'Утренний стендап',
        period: null,
        image: 'standup',
      },
      {
        time: '9:20 − 11:00',
        topic: 'лекция',
        period: null,
        image: null,
      },
      {
        time: '11:00 − 12:30',
        topic: 'Практика соло, в парах или в группах',
        period: null,
        image: 'coding',
      },
      {
        time: '12:30 − 14:00',
        topic: 'Обед / английский разговорный клуб',
        period: null,
        image: null,
      },
      {
        time: '14:00 − 14:15',
        topic: 'Дневной стендап',
        period: null,
        image: null,
      },
      {
        time: '14:15 − 17:30',
        topic: 'Решение задач, работа над проектами',
        period: null,
        image: 'practice',
      },
      {
        time: '17:30 − 17:45',
        topic: 'вечерний стендап',
        period: null,
        image: null,
      },
      {
        time: '18:00',
        topic: 'встреча с выпускниками',
        period: null,
        image: null,
      },
      {
        time: '19:00',
        topic: 'лекция по алгоритмам',
        period: null,
        image: 'algorithm',
      },
    ],
  },
  teachers: {
    subtitle: 'наши',
    title: 'Преподаватели',
  },
  employmentDescription: {
    title: 'Трудоустройство',
    markedSubTitle:
      'Карьерные коучи помогают с резюме, консультируют по IT-рынку, делятся лайфхаками по трудоустройству и остаются на связи в течение года после выпуска.',
    statistic: {
      value: '86%',
      description: 'студентов находят работу за 3 месяца.',
      link: {
        url: '/job-report2023',
        title: 'Исследование hh.ru',
      },
    },
  },
  projectsAndReviews: {
    projects: {
      title: 'Реальные проекты',
      image: 'reviews/projectsTitle',
      ymEvent: 'DSProjects',
    },
    reviews: {
      title: 'Отзывы',
      image: 'reviews/reviewsTitle',
      ymEvent: 'DSComments',
    },
  },
  graduates: {
    data: {
      people: [
        {
          photo: 'ivanova',
          fullName: 'Елена Иванова',
          content:
            'До Эльбруса работала младшим научным сотрудником в R&D центре компании Генериум, а теперь я старший аналитик направления моделирования и оперативного анализа в банке Ренессанс. Получила оффер в тот же месяц, в который выпустилась из буткемпа.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/iva_elen',
            },
          ],
        },
        {
          photo: 'dmitrenok',
          fullName: 'Константин Дмитренок',
          content:
            'Выпустился в 2022 году, работаю аналитиком данных в ритейле. Больше всего из полученных навыков в реальной работе пригодилось умение самостоятельно работать над проектами, теория вероятности и статистика, работа с эмбеддингами и NLP.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/kostyagomel44',
            },
          ],
        },
        {
          photo: 'opravin',
          fullName: 'Никита Оправин',
          content:
            'Работаю ML-инженером в дочке крупной международной компании, получил оффер меньше чем за месяц с начала поисков. За время буткемпа я осознал, что далеко не всё можно понять с первого раза, важен сам процесс изучения. Это помогло мне проще переваривать новую информацию во время обучения и работы.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/opravinnikita',
            },
          ],
        },
      ],
    },
  },
  payment: {
    program: 'Data Science bootcamp',
    programType: 'Основная программа',
    title: 'Стоимость обучения',
    subtitle: 'data science bootcamp',
    data: {
      description: [
        {
          content: 'Окупаемость обучения уже на 3-й месяц.',
          link: {
            text: 'Данные',
            title: 'исследования hh.ru',
            url: '/job-report2023/',
          },
          icon: 'wallet',
        },
        {
          content:
            'Главная цель — трудоустройство. 86% студентов находят работу в течение 3 месяцев',
          icon: 'star',
        },
      ],
    },
    ymEvents: {
      paymentFormSubmitCredit: 'DSCredit',
      paymentFormSubmitFullPrice: 'DSPriceFull',
    },
  },
  education: {
    title: 'Data Science из любой точки мира',
    subtitle: 'Обучение',
    data: {
      list: [
        'Слушаете лекции в режиме реального времени и получаете обратную связь от преподавателя',
        'Проводите code-review с ментором',
        'Работаете над проектами от заказчика',
        'После обучения готовитесь к собеседованиям вместе с карьерным коучем',
      ],
    },
  },
  details: {
    title: 'Главное',
    subtitle: 'Детали курса',
    details: [
      {
        title: 'График',
        info: '8 часов в день по будням',
        Icon: CalendarIcon,
      },
      {
        title: 'Часы занятий',
        info: '9:00 − 18:00',
        Icon: ClockIcon,
        // format: 'Онлайн',
      },
      {
        title: 'Профессии после обучения',
        info: 'Data Scientist, Аналитик данных, Дата‑инженер',
        Icon: DSIcon,
      },
    ],
  },
  groups: {
    title: 'Потоки обучения',
    subtitle: 'актуальная',
    program: 'Datascience',
    type: 'основная программа',
    defaultMessage: 'Не подходит дата',
    ymEvents: [
      {
        groupsFormOpen: 'DSFirstCardFormOpen',
        groupsFormSubmit: 'DSFirstCardFormSent',
      },
      {
        groupsFormOpen: 'DSSecondCardFormOpen',
        groupsFormSubmit: 'DSSecondCardFormSent',
      },
    ],
  },
  structure: {
    title: ['Программа обучения', 'Data Science онлайн'],
    minHeight: '255px',
    list: [
      {
        period: '1-3 неделя',
        phase: 'фаза 0',
        description:
          'Базовые инструменты работы: IDE, bash, git. Python и понятия ООП. Работа с данными и визуализацией. Занятость 6-8 часов в день.',
        backgroundColor: 'kit.background.gray',
        marginTop: '0px',
        minHeight: '174px',
      },
      {
        period: '4-7 неделя',
        phase: 'фаза 1',
        description:
          'Изучаем необходимый минимум математики и статистики, A/B-тестирование, с помощью классических моделей машинного обучения решаем базовые задачи: классификация, регрессия, кластеризация.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-142px',
        minHeight: '208px',
      },
      {
        period: '8-11 неделя',
        phase: 'фаза 2',
        description:
          'Решаем задачи с помощью нейросетей. Изучаем pytorch. Строим полносвязные, свёрточные, рекуррентные сети. Применяем модели для классификации изображений, детекции объектов, классификации текста, рекомендаций.',
        backgroundColor: 'kit.background.darkgray',
        marginTop: '-110px',
        minHeight: '172px',
        height: '190px',
      },
      {
        period: '12-15 неделя',
        phase: 'фаза 3',
        description:
          'Деплой моделей. Разворачиваем обученные модели на облачных серверах, настраиваем PostgreSQL, Superset, Airflow. Выполняем финальный групповой проект.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-130px',
        minHeight: '140px',
        height: '170px',
      },
      {
        period: '5 дней',
        noTitleImg: true,
        phase: 'Карьерная неделя',
        description:
          'Стратегия по поиску работы, составление резюме, подготовка к собеседованиям.',
        backgroundColor: 'kit.background.middlepurple',
        marginTop: '-150px',
        minHeight: '134px',
      },
    ],
    ymEvents: {
      programFormSubmit: 'DSProgram',
    },
  },
  entranceInterview: {
    title: 'Вступительное интервью в Эльбрус Буткемп',
    video: 'https://www.youtube.com/embed/61Ywd660xo0',
    list: [
      'Перед поступлением будущих программистов ждет техническое интервью. Это нужно, чтобы студенты поняли, с чем им предстоит работать в ближайшие месяцы и убедились в серьезности своих намерений.',
      'Формат: ответы на вопросы и решение задач на видеозвонке, 45 минут',
    ],
    ymEvent: 'DSVideoInterview',
  },
  examPrepare: {
    title: 'Как подготовиться к интервью?',
    preCourseTitle: 'Основы Python•Онлайн',
    selfPrepareTitle: 'Самостоятельная подготовка',
    description:
      'Подготовка к вступительному к интервью занимает от 2‑х до 4‑х недель. Вы можете подготовиться самостоятельно или с ментором в группе.',
    preCourse: {
      duration: '2 недели',
      hours: '20 часов',
      pointsList: [
        '10 живых занятий с ментором',
        'Индивидуальная обратная связь по задачам от ментора',
        'Поддержка в закрытом чате',
      ],
      link: {
        url: '/datascience-basics/',
        title: 'Подробнее',
      },
      ymEvent: 'DSPreCourses',
    },
    trainer: {
      headerText: 'Материалы для подготовки',
      hours: '60 часов',
      pointsList: [
        'Подготовка к задачам по Python, теории вероятности и статистике',
        'Вопросы для самопроверки',
        'Чат поддержки',
      ],
      ymEvent: 'DSMaterials',
    },
  },
};

export const intenseData = {
  seo: {
    title:
      'Data Scientist обучение с нуля | Курс Python Junior для анализа данных онлайн',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Курсы Python для Data Science для начинающих онлайн. Обучение на Junior Data Scientist с нуля с трудоустройством. План обучения курса анализа данных на сайте школы Эльбрус Буткемп.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Data Scientist обучение с нуля | Курс Python Junior для анализа данных онлайн',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Курсы Python для Data Science для начинающих онлайн. Обучение на Junior Data Scientist с нуля с трудоустройством. План обучения курса анализа данных на сайте школы Эльбрус Буткемп.',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/developer-9f1b6e5e395c2f3f8b555781f0d17be1.png',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/developer-9f1b6e5e395c2f3f8b555781f0d17be1.png',
      },
    ],
  },
  intro: {
    title: 'Основы Data Science',
    description:
      'Прокачайте знания Python и постройте предсказательную модель по выживаемости пассажиров Титаника.',
    bg: 'ds-precourse-intro',
    link: '/payment/?leartype=ds.prepare.ru',
    metrics: {
      ym: 'PreDSTopBuy',
      mt: '',
    },
  },
  benefits: [
    {
      type: '10',
      text: 'живых практических занятий: Python, Pandas, scikit-learn, Keras',
    },
    {
      type: '14',
      text: 'дней тест-драйва профессии Data Scientist',
    },
    {
      type: '20',
      text: 'часов эффективных занятий в сопровождении ментора ',
    },
    {
      type: '2',
      text: 'месяца доступа к материалам курса',
    },
  ],
  target: {
    title: 'Кому подойдет курс',
    list: [
      {
        title: 'Тем, кто хочет попробовать себя в Data Science',
        explaining:
          'Вы мечтаете сменить профессию и работать с данными, а еще, возможно, любите математику и статистику.',
        image: 'questions',
        margin: { xs: '-10px', md: '-25px' },
      },
      {
        title: 'Тем, кто немного знает Python',
        explaining:
          'Вы хотите закрепить знания, освоить инструменты работы с данными и построить свою первую предсказательную модель.',
        image: 'lections',
        margin: '-1px',
      },
      {
        title: 'Тем, кто хочет подготовиться к вступительному интервью',
        mobileTitle: 'Тем, кто хочет подготовиться к интервью',
        explaining:
          'Вы нацелены поступать в буткемп и хотите быть уверены в своих знаниях.',
        image: 'interview',
        margin: '-11px',
      },
    ],
  },
  details: {
    title: 'Детали курса',
    subtitle: '',
    details: [
      {
        title: 'График',
        info: '2 недели по будням ',
        Icon: CalendarIcon,
      },
      {
        title: 'Длительность занятия',
        info: '90 минут по вечерам ',
        Icon: ClockIcon,
        format: 'онлайн',
      },
    ],
  },
  whatYouGet: {
    title: 'Что вас ждет?',
    details: [
      'Живые занятия в группе и первые знакомства в IT-комьюнити',
      'Построение вашей первой предсказательной модели и нейросети',
      'Поддержка ментора по любым вопросам и обратная связь по итогам занятий',
    ],
    image: 'whatYouGetDs',
    durationImg: '15hoursDs',
  },
  program: {
    title: ['Программа погружения', 'в основы Data Science'],
    list: [
      {
        day: 'Неделя 1',
        points: [
          'Google colab, jupyter notebook и основы python',
          'Типы данных, условия, циклы',
          'Лямбда-функции, map, reduce',
          'Математические функции в python',
        ],
        description: null,
        image: null,
      },
      {
        day: 'Неделя 2',
        points: [
          'Классы. ООП',
          'Виртуальная среда. Установка Библиотек. Kaggle‑соревнования',
          'Обзор и анализ данных с библиотекой pandas',
          'Методы машинного обучения с scikit-learn и keras',
          'Оформление исследования и Kaggle-соревнования',
        ],
        description: null,
        image: null,
      },
      // {
      //   day: 'Среда',
      //   points: [
      //     'Условные ветвления, тернарный оператор',
      //     'Копирование и сравнение по ссылке',
      //     'Массивы: PUSH, POP, Shift, Unshift, обращение через [ ]',
      //   ],
      //   description: null,
      //   image: null,
      // },
      // {
      //   day: 'Четверг',
      //   points: [
      //     'Циклы: while, for..let, for..of, do..while',
      //     'Методы массивов slise и splice. Includes, indexof, find, reverse',
      //     'Методы и операции со строками: split (и join), tolowercase, touppercase, slise. Сложение и template literal.',
      //   ],
      //   description: null,
      //   image: null,
      // },
      // {
      //   day: 'Пятница',
      //   points: [
      //     'Функции. Суть, способы объявления',
      //     'Области видимости',
      //     'Методы массивов: map, foreach, filter, sort, reduse',
      //   ],
      //   description: null,
      //   image: null,
      // },
      // {
      //   day: 'Неделя практики',
      //   points: null,
      //   description:
      //     'Вы будете решать задачи — от простых к более сложным, выполнять домашние задания и разбирать их вместе с ментором.',
      //   image: 'precourse-practice',
      // },
    ],
    buttonText: 'получить материалы',
    explication: {
      image: 'python',
      text: 'Перед курсом необходимо знать базовый синтаксис python. Вышлем бесплатные материалы для подготовки.',
    },
    metrics: {
      ym: 'PreDSProgram',
    },
  },
  courseExpectations: {
    title: 'Что даст вам курс',
    image: 'expectations-ds',
    tallCard: true,
    details: [
      {
        title: 'Тест-драйв профессии Data Scientist',
        description:
          'Узнаете об особенностях направления, построите свою первую модель машинного обучения и поймете, подходит ли вам сфера.',
        Icon: DSlargeIcon,
        isVisible: true,
      },
      {
        title: 'Индивидуальный проект',
        description:
          'Проведете исследование на основе данных пассажиров Титаника и оформите его.',
        Icon: StonksIcon,
        isVisible: true,
      },
      {
        title: '',
        description: '',
        Icon: ClockIcon,
        isVisible: false,
      },
      {
        title: '',
        description: '',
        Icon: ClockIcon,
        isVisible: false,
      },
      {
        title: 'Полезные контакты',
        description:
          'Заведете первые знакомства в IT-сфере и получите доступ в закрытые чаты, где можно обмениваться опытом и задавать вопросы.',
        Icon: PeopleIcon,
        isVisible: true,
      },
      {
        title: 'Готовность к поступлению в Эльбрус Буткемп',
        description:
          'Пройдете все темы, необходимые для успешного прохождения технического интервью.',
        Icon: RocketIcon,
        isVisible: true,
      },
    ],
  },
  price: {
    title: 'Стоимость обучения',
    link: '/payment/?leartype=ds.prepare.ru',
    points: [
      'Фундамент для старта в IT',
      'Живые лекции и структурированные знания',
      'Много практики и групповые разборы домашних заданий с ментором',
    ],
    bonuses: [
      {
        point: '2 месяца доступа к лекциям в записи',
        image: 'video-lections',
      },
      {
        point: '>15 часов практики',
        image: 'practice-time',
      },
      {
        point: 'Курс можно пройти бесплатно*',
        image: 'discount',
      },
    ],
    metrics: {
      ym: 'PreDSPriseBuy',
    },
  },
  about: {
    title: 'О школе Эльбрус Буткемп',
    statistic: [
      {
        id: 6,
        value: '--',
        description: 'студентов успешно...',
        link: null,
      },
      {
        id: 1,
        value: '--',
        description: 'студентов выпущено...',
        link: null,
      },
      {
        id: 2,
        value: '--',
        description: 'студентов находят работу..',
        link: {
          title: 'исследование hh.ru',
          url: '/job-report2023/',
        },
      },
    ],
    description:
      'Наши карьерные коучи помогают с резюме, консультируют по IT‑рынку, делятся лайфхаками по трудоустройству и остаются на связи в течение года после выпуска.',
  },
  pathToIt: {
    title: 'Ваш путь в IT',
    details: [
      {
        text: 'Задумались попробовать себя в Data Science и получили',
        linkName: 'бесплатные материалы для подготовки',
        link: 'https://www.figma.com/file/4G5o4WZ9OworkJTuyT6xzf/ECB-NEWGRID?type=design&node-id=13859-27143&mode=design&t=hXnRhxVVtm3xNyYw-0',
      },
      {
        text: '«Основы JavaScript»',
        linkName: 'Запишитесь на курс',
        link: 'https://www.figma.com/file/4G5o4WZ9OworkJTuyT6xzf/ECB-NEWGRID?type=design&node-id=13859-27143&mode=design&t=hXnRhxVVtm3xNyYw-0',
      },
      {
        text: 'Пройдете вступительное интервью в Эльбрус Буткемп',
      },
      {
        text: 'Освоите востребованную IT-профессию',
      },
      {
        text: 'Получите первый оффер — самое интересное впереди!',
      },
    ],
  },
  faq: {
    title: 'Ответы на частые вопросы',
  },
  reviews: {
    title: 'Отзывы тех, кто прошел курс',
    image: 'reviews/reviewsTitle',
    ymEvent: 'PreDSComments',
  },
  graduates: {
    data: {
      people: [
        {
          photo: 'karpenko',
          fullName: 'Сергей Карпенко',
          content:
            'До курса я знал немного Python и прошел пару курсов статистики на Stepik. Самостоятельная подготовка заняла бы гораздо больше времени — даже на курсе у меня была тема — Machine Learning — на которой я долго не понимал, что происходит:)',
        },
        {
          photo: 'ivanova1',
          fullName: 'Елена Иванова',
          content:
            'Подготовительный курс дал понимание, что меня ждет на основном курсе: много работы, много задач, которые можно решить тысячами способами — и все они верные. А еще именно здесь я впервые познакомилась с нейронками — такими интересными и абсолютно непонятными на тот момент.',
        },
        {
          photo: 'bystrov',
          fullName: 'Илья Быстров',
          content:
            'Курс дает хорошую возможность понять, нравится ли тебе вся эта движуха с Data Science или нет. Ну и плюсом у тебя появляются структурированные знания и команда единомышленников. Лично мне он помог немного структурировать знания и понять, для чего я вообще учил какие-то вещи в Python.',
        },
      ],
    },
  },
  footer: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      consultationFormSubmit: 'PreDSBottomConsultation',
    },
    // myTargetEvents: {
    //   consultationFormSubmit: 'PreDSBottomConsultation',
    // },
  },
};

export default data;
