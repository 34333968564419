import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CardDSIcon(props) {
  return (
    <SvgIcon viewBox="0 0 36 36" sx={{ width: 36, height: 36 }} {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="15" fill="#4520AB" />
        <path
          d="M12.2826 22.8125H9V12.1458H12.4281C13.46 12.1458 14.3429 12.3594 15.077 12.7865C15.8111 13.2101 16.3738 13.8194 16.7651 14.6146C17.1564 15.4063 17.352 16.3542 17.352 17.4583C17.352 18.5694 17.1547 19.526 16.7599 20.3281C16.3652 21.1267 15.7904 21.7413 15.0355 22.1719C14.2806 22.599 13.363 22.8125 12.2826 22.8125ZM10.2881 21.6667H12.1995C13.0791 21.6667 13.808 21.4965 14.3862 21.1563C14.9645 20.816 15.3956 20.3316 15.6795 19.7031C15.9635 19.0747 16.1055 18.3264 16.1055 17.4583C16.1055 16.5972 15.9652 15.8559 15.6847 15.2344C15.4043 14.6094 14.9853 14.1302 14.4278 13.7969C13.8703 13.4601 13.176 13.2917 12.345 13.2917H10.2881V21.6667Z"
          fill="#29EDFF"
        />
        <path
          d="M25.608 14.8125C25.5457 14.2847 25.2929 13.875 24.8497 13.5833C24.4064 13.2917 23.8628 13.1458 23.2187 13.1458C22.7478 13.1458 22.3357 13.2222 21.9825 13.375C21.6328 13.5278 21.3593 13.7378 21.1619 14.0052C20.968 14.2726 20.871 14.5764 20.871 14.9167C20.871 15.2014 20.9385 15.4462 21.0736 15.651C21.2121 15.8524 21.3887 16.0208 21.6034 16.1562C21.8181 16.2882 22.0431 16.3976 22.2786 16.4844C22.5141 16.5677 22.7305 16.6354 22.9279 16.6875L24.0082 16.9792C24.2852 17.0521 24.5934 17.1528 24.9328 17.2813C25.2756 17.4097 25.6028 17.5851 25.9144 17.8073C26.2295 18.026 26.4893 18.3073 26.6936 18.651C26.8979 18.9948 27 19.4167 27 19.9167C27 20.4931 26.8494 21.0139 26.5481 21.4792C26.2503 21.9444 25.814 22.3142 25.2392 22.5885C24.6679 22.8628 23.9736 23 23.1564 23C22.3946 23 21.735 22.8767 21.1775 22.6302C20.6234 22.3837 20.1871 22.0399 19.8686 21.599C19.5535 21.158 19.3751 20.6458 19.3336 20.0625H20.6633C20.6979 20.4653 20.8329 20.7986 21.0684 21.0625C21.3073 21.3229 21.6086 21.5174 21.9722 21.6458C22.3392 21.7708 22.734 21.8333 23.1564 21.8333C23.6481 21.8333 24.0896 21.7535 24.4809 21.5938C24.8722 21.4306 25.1821 21.2049 25.4106 20.9167C25.6392 20.625 25.7534 20.2847 25.7534 19.8958C25.7534 19.5417 25.6547 19.2535 25.4574 19.0313C25.26 18.809 25.0003 18.6285 24.6783 18.4896C24.3562 18.3507 24.0082 18.2292 23.6343 18.125L22.3254 17.75C21.4943 17.5104 20.8364 17.1684 20.3516 16.724C19.8668 16.2795 19.6244 15.6979 19.6244 14.9792C19.6244 14.3819 19.7855 13.8611 20.1075 13.4167C20.433 12.9688 20.8693 12.6215 21.4164 12.375C21.967 12.125 22.5816 12 23.2603 12C23.9459 12 24.5553 12.1233 25.0886 12.3698C25.6218 12.6128 26.0443 12.9462 26.3559 13.3698C26.671 13.7934 26.8373 14.2743 26.8546 14.8125H25.608Z"
          fill="#29EDFF"
        />
      </svg>
    </SvgIcon>
  );
}
